.wrapper-project-div {
  display: flex;
  /* height: 100vh;
  width: 100vw; */
  height: 100%;
  width: 100%;
  /* horizontal center */
  justify-content: center;
  /* vertical center */
  align-items: center;
}

.project-div {
  /* text-align: center; */
  border: 1px solid #9e9e9e;
  border-radius: 15px;
  max-width: 600px;
  min-width: 500px;
  padding: 20px 25px;
  /* height: 1000px; */
}
