.wrapper-login-div {
  display: flex;
  height: 100vh;
  width: 100vw;
  /* horizontal center */
  justify-content: center;
  /* vertical center */
  align-items: center;
}

.login-div {
  text-align: center;
  border: 1px solid #9e9e9e;
  border-radius: 15px;
  width: 400px;
  padding: 80px 50px;
  height: 500px;
}

.help-link:hover {
  text-decoration: underline;
  text-decoration-color: black;
  -webkit-text-decoration-color: black; /* Safari */
  text-underline-position: under;
}
