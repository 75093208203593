@font-face {
  font-family: "NotoSans-Regular";
  src: url(./assets/fonts/NotoSans-Regular.ttf);
}
@font-face {
  font-family: "NotoSans-Light";
  src: url(./assets/fonts/NotoSans-Light.ttf);
}
@font-face {
  font-family: "NotoSans-No-Light";
  src: url(./assets/fonts/NotoSans-ExtraBoldItalic.ttf);
}
/* :root {
  --font-notoSansLight: "NotoSans-Light";
} */
html {
  font-size: 50%;
}
body {
  font-size: 1rem;
  margin: 0;
  font-family: "NotoSans-No-Light", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span {
  font-family: "NotoSans-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu";
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

/* 드래그 앤 드롭 비활성화 스타일 */
.element-no-drag {
  -webkit-user-drag: none; /* Safari 및 이전 버전의 Chrome에서 동작 */

  user-select: none; /* 텍스트 선택을 비활성화 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (max-width: 300px) {
  html {
    font-size: 40%;
  }
}

@media (min-width: 500px) {
  html {
    font-size: 60%;
  }
}

@media (min-width: 700px) {
  html {
    font-size: 80%;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 100%;
  }
}

.markdown-content ul,
.markdown-content ol {
  padding-left: 10px;
}
.markdown-content img {
  /* height: "1vw"; */
  height: 1vw;
}
.markdown-content em {
  font-style: normal;
  /* font-weight: bold; */
  color: blue;
}
.markdown-content code {
  font-style: normal;
  /* font-weight: bold; */
  color: #74aa9c;
}

.markdown-content h6 {
  /* text-decoration: underline; */
  font-weight: bold;
}
